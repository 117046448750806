@use 'sass:color';

.wrapper {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
}

.content {
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 142px;
  height: 90px;
}

.title {
  margin: 35px 0 0;
  font-size: 36px;
  line-height: 36px;
  color: #333;
  font-weight: 600;
}

.description {
  margin: 25px 0 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #a6a6a6;
}

.namespacesList,
.spinnerWrapper {
  margin: 20px 0 0;
}

.namespacesList {
  $color: #6288c5;

  list-style-position: inside;
  font-size: 14px;
  line-height: 22px;
  color: $color;

  a {
    text-decoration: none;
    color: $color;

    &:hover {
      text-decoration: underline;
      color: color.adjust($color, $lightness: -10%);
    }
  }
}

.spinnerWrapper {
  display: flex;
  justify-content: flex-start;
}
